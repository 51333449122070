
import { Vue, Component, Watch } from 'vue-property-decorator';
import { universalRegExpChecker } from '@/utils/validationUtils';
import BaseButtonLanding from '@/components/landingAndRegistrationComponents/BaseButtonLanding.vue';
import BaseInputLanding from '@/components/landingAndRegistrationComponents/BaseInputLanding.vue';

@Component({
  components: {
    BaseInputLanding,
    BaseButtonLanding,
  },
})
export default class LoginComponent extends Vue {
  errorEmail = false;
  timeout: null | number = null;

  get email(): string {
    return this.$store.state.user.email;
  }
  set email(value: string) {
    this.$store.commit('user/setEmail', value);
  }
  get buttonText(): string {
    switch (this.$route.name) {
      case 'Landing360Page':
        return 'START FREE NOW';
      case 'LandingCreditScorePage':
        return 'Get your free credit score';
      case 'LoginPage':
        return 'NEXT';
      default:
        return 'GET YOUR FUNDING';
    }
  }

  goToRegistration(): void {
    if (this.checkEmail()) this.$router.push({ name: 'RegistrationPage' });
  }
  checkEmail(): boolean {
    this.errorEmail = !universalRegExpChecker('email', this.email);
    return !this.errorEmail;
  }

  @Watch('email')
  watchEmail(): void {
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.errorEmail = !universalRegExpChecker('email', this.email);
    }, 500);
  }
}
